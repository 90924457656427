import * as React from "react"
import { graphql, Link } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import SimpleReactLightbox, { SRLWrapper } from "simple-react-lightbox"
import { ChevronLeftIcon } from '@heroicons/react/solid'
import Button from '../components/button'

const lightboxOptions = {
    buttons: {
        showDownloadButton: false
    }
}

const PartyDetail = ({ data }) => {
    
    console.log(data)

    const { body } = data.event
    const {
        date, 
        venue, 
        dresscode, 
        people_limit, 
        price, 
        price_note,
        ticket_link,
    } = data.event.frontmatter

    const bannerImage = getImage(data.event.frontmatter.banner_image)

    return (
        <>
            <div className='md:container mx-auto md:grid md:grid-cols-3'>
                <div className='md:col-span-2'>

                    <div className='bg-black text-center mb-6'>
                        <GatsbyImage 
                            image={bannerImage}
                            alt="testing"
                            
                            layout="fullWidth"
                        />
                    </div>

                    <div className='pr-12 p-6 md:pt-6'>
                        <h5 className='mb-6'>DESCRIPTION</h5>
                        <div>
                            <MDXRenderer>
                                {body}
                            </MDXRenderer>
                        </div>
                        <div>
                            {/* Photo Gallery */}
                            <SimpleReactLightbox>
                                <SRLWrapper options={lightboxOptions}>
                                    <div className='grid grid-cols-4 gap-2 mt-12'>
                                        { data.eventPhotos.edges.map(photo => (
                                            <div key={photo.node.id}> <GatsbyImage 
                                                    image={ getImage(photo.node )}
                                                    alt="testing"
                                                />
                                            </div>
                                        ))}
                                    </div>
                                </SRLWrapper>
                            </SimpleReactLightbox>
                        </div>
                    </div>
                </div>
                <div className='md:col-span-1 pl-6 pt-6 pb-6 bg-[#111] flex flex-col space-y-6'>

                    <div>
                        <Link to='/party' className='flex flex-row'>
                            <div>
                                <ChevronLeftIcon className='h-5 w-5 text-primary' />
                            </div>
                            <div className='text-sm font-medium text-primary'>
                                 Back to Events
                            </div>
                        </Link>
                    </div>

                    <div>
                        <a href={ ticket_link } target='_blank' rel="noreferrer">
                            <Button text="Buy Ticket" width="w-1/2"></Button>
                        </a>
                    </div>
                    
                    <div>
                        <h5 className='title--event'>date and time</h5>
                        {/* {data.mdx.frontmatter.date} */}
                        {date}
                    </div>

                    <div>
                        <h5 className='title--event'>venue</h5>
                        {venue}
                    </div>

                    <div>
                        <h5 className='title--event'>maximum party capacity</h5>
                        {people_limit}
                    </div>

                    <div>
                        <h5 className='title--event'>ticket price</h5>
                        { price } <br />
                        <span className='text-sm italic'>{ price_note }</span>
                    </div>

                    <div>
                        <h5 className='title--event'>dress code</h5>
                        { dresscode }
                    </div>
                </div>
            </div>

            
            
        </>
    )
}

export const query = graphql`
query EventDetail($locale: String!, $title: String, $photo_dir: String) {
    event:mdx(
        frontmatter: { title: { eq: $title } }
        fields: { locale: { eq: $locale } }
        ) {
        frontmatter {
          gallery_dir
          date
          city
          type
          venue
          slogen
          dresscode
          people_limit
          price
          price_note
          ticket_link
          title
          venue
          banner_image {
            childImageSharp {
                gatsbyImageData(
                    placeholder: DOMINANT_COLOR
                    height: 600
                )
            }
          }
        }
        body
    }
    eventPhotos:allFile(
          filter: {extension: {regex: "/(jpg)|(png)|(jpeg)/"}, relativeDirectory: {eq: $photo_dir}}
      ) {
          edges {
            node {
                id
                base
                childImageSharp {
                    gatsbyImageData(aspectRatio: 1.5)
                }
            }
          }
      }
}
`

export default PartyDetail